<template>
  <div class="body">
    <!-- 导航栏 -->
    <!-- <Nav /> -->
    <div class="content ">
      <span>欢迎您登陆使用导师平台</span>

      <span style="float: right;" @click="logout">
        <span>{{ ExpertInfo.name }}</span>
        <span style="margin-left: 10px;border-right:1px solid #707070;padding-right: 10px;" class="logout">{{
          ExpertInfo.mobile
          }}</span>
        <span class="logout" style="padding-left: 40px;">退出账号</span>
      </span>
    </div>

    <router-view />
  </div>
</template>

<script>
// import Nav from "@/components/nav";
import { mapState } from "vuex";

export default {
  name: "",
  components: {
    // Nav,
  },
  computed: {
    ...mapState(["tabIndex"]),
  },
  data() {
    return {
      ExpertInfo: []
    };
  },
  created() {

    // 获取下级导师列表
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));

  },
  methods: {
    logout() {
      this.$alert("您确认要退出吗！", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            localStorage.clear();
            sessionStorage.clear();
            this.$router.push("/login");
          }
        },
      });
    },
  },
};
</script>
<style lang="less">
.page {
  margin-top: 5px;

}
</style>

<style scoped lang='less'>
a {
  color: black;
}

.body {
  width: 1400px;
  margin: 0 auto;
  background: #E8F7FB;
  font-size: 18px;
}

.content {
  width: 1400px;
  padding: 20px 60px;
  background: white;

  .logout {
    color: #A7A7A7;
  }
}
</style>