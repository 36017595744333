<template>
  <div class="interest-page">
    <div v-if="state">
      <div v-if="!show">
        <div class="title">欢迎使用“大学专业选择测评（新高考）”！</div>
        <div class="f16">
          <p class="f20 mb10">正式测评前请认真阅读以下指导语：</p>
          <p>1. 本测评总共{{ len }}题，建议测评时长不超过 15 分钟；</p>
          <p>
            2.
            本测评全部为单项选择题，所有答案无对错好坏之分，请选择最符合自身情况的选项。难以抉择的选项建议第一感觉选择，不宜作过多思考；
          </p>
          <p>3. 测评过程中请确保环境安静舒适，不被打扰；</p>
          <p>
            4.
            测评结果将作为专业选择的重要参考依据，关乎个人前途，请务必认真完成。
          </p>
          <p>
            5.
            ★MBTI模型源自荣格(瑞士)与Myers-Briggs(美国)。Holland理论由美国约翰·霍普金斯大学心理学教授Holland提出。Career
            Anchor Theory由美国麻省理工大学斯隆商学院埃德加·H·施恩提出。
          </p>
        </div>
        <span class="btn" @click="start">开始测评</span>
      </div>

      <div v-else>
        <div class="title">
          <el-row>
            <el-col :span="6">大学专业选择测评（新高考）</el-col>
            <el-col :span="3" :offset="15"><span class="f16">用时：{{ timeStr }}</span></el-col>
          </el-row>
        </div>
        <div class="f16">
          <div v-for="(item, i) in list" :key="i" style="padding: 0 30px">
            <div v-if="curt == i">
              <p class="num f20">
                <span>{{ curt + 1 }}</span>/{{ len }}
              </p>
              <p class="question f26">{{ i + 1 }}、{{ item.question }}</p>
              <div class="answers">
                <span class="answer" v-for="(val, key) in item.answer" :key="key" @click="doClick(i, val.weight)">
                  {{ val.title }}
                </span>
              </div>
            </div>
          </div>
          <el-progress :percentage="num" status="success" style="margin-top: 20px" />
        </div>
      </div>
    </div>

    <div style="text-align: center;
    background-color: beige;
    line-height: 400px;
    " v-else>
      已完成测评
    </div>

  </div>
</template>

<script>

export default {
  name: "interest",
  data() {
    return {
      show: false,
      list: [],
      len: 0,
      curt: 0,
      answers: [],
      num: 0,
      time: 0,
      timeStr: "00分00秒",
      timer: null,
      add: true,
      userInfo: null,
      state:true
    };
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    checkTime(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    getUserInfo() {
      this.user_id = this.$route.query.id

      //获取用户信息
      this.$fecth.post("user/getUserInfo", { id: this.user_id }).then((res) => {
        let { code, data } = res;
        if (code == 200) {
          this.userInfo = data
        }
      });
      if (this.user_id) {
        this.getAssess()
      }
    },
    //获取题目
    getAssess() {
      this.$fecth
        .get("Appraisal/getAppraisal", {
          params: {
            url:
              "http://www.apesk.com/major-choice/index_hr_2_tongji_100_xiazai_111_new.asp",
            hr_email: "18537129969",
            checkcode: "51ECS7OIFOZAOGM3ZK",
          },
        })
        .then((res) => {
          if (res.data.Data) {
            this.list = res.data.Data;
            this.len = this.list.length;
          }
        });
    },

    start() {
      this.show = true;
      if (this.timer) return false;
      this.timer = setInterval(() => {
        this.time++;
        if (this.time > 59) {
          const s = this.checkTime(this.time % 60);
          const m = this.checkTime(Math.floor(this.time / 60));
          this.timeStr = m + "分" + s + "秒";
        } else {
          const num = this.checkTime(this.time);
          this.timeStr = "00分" + num + "秒";
        }
      }, 1000);
    },

    //提交测评获取结果
    doClick(i, val) {
      this.answers.push({
        id: i + 1,
        answer: val,
      });
      if (i + 1 >= this.list.length && this.add) {
        this.add = false;
        clearInterval(this.timer);
        this.timer = null;
        this.$fecth
          .post("Appraisal/getUrls", {
            user_id: this.user_id,
            url:
              "http://www.apesk.com/lee/jsonsubmit/submit_zyxz_conn_json_g3_new.asp",
            // answer_url: "http://www.apesk.com/lee/new/index_2_utf.asp",
            name: "大学专业选择测评（新高考）",
            ParamName: "AnswerData",
            AnswerData: JSON.stringify({
              liangbiao: "Major-choice-new",
              checkcode: "51ECS7OIFOZAOGM3ZK",
              test_name: this.userInfo ? this.userInfo.user_name : "游客",
              test_email: "gkfun_2585_8241@sina.com",
              hr_email: "18537129969",
              answers: this.answers,
            }),
          })
          .then(() => {
            this.state = false;
          });
      } else {
        this.curt = i + 1;
        this.num = (this.curt / this.len) * 100;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.interest-page {
  width: 1100px;
  overflow: hidden;
  min-height: 1000px;
  background: white;
  margin: 0px auto;
  padding: 40px;

  .title {
    color: #333333;
    font-size: 18px;
    padding: 10px 0;
    border-bottom: 2px solid #f2f2f2;
  }

  .f26 {
    font-size: 16px;
  }

  .f16 {
    margin-bottom: 20px;
    font-size: 16px;
    color: #666666;

    p {
      margin-top: 10px;
    }
  }

  .f20 {
    font-size: 16px;
  }

  .btn {
    display: inline-block;
    background: #1787e0;
    height: 40px;
    width: 160px;
    line-height: 40px;
    margin-left: 800px;
    text-align: center;
    color: #fff;
    border-radius: 4px;
    margin-top: 30px;
  }

  .answers {
    margin-top: 40px;

    .answer {
      display: inline-block;
      padding: 10px 40px;
      border: 2px solid #f2f2f2;
      border-radius: 6px;
      color: #454545;
      font-size: 20px;
      margin-right: 30px;

      &:hover {
        background-color: #f2f2f2;
      }
    }
  }
}
</style>
