<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    this.$nextTick(() => {
      // 禁用右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁用选择
      document.onselectstart = new Function("event.returnValue=false");
    });
  },
};
</script>

<style lang="less">
#app {
  min-height: 100vh;
  font-family: AlibabaPuHuiTi;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #E8F7FB;
}

* {
  text-decoration: none;
  padding-left: 0;
  margin: 0;
  box-sizing: border-box;
}

.pagination {
  padding: 40px 0;

  .page-box {
    text-align: center;
  }
}
</style>
