<template>
  <div>
    <div>
      <user_list :UserListData="UserListData" @refresh="getList()"> </user_list>
    </div>
  </div>
</template>

<script>
import user_list from "./components/UserList";
export default {
  props: ['check', 'onSearch'],
  name: '',
  components: {
    user_list,

  },
  watch: {
    onSearch() {
      this.getList()
    },
  },
  data() {
    return {
      UserListData: [],
      ExpertInfo: "",
    };
  },
  computed: {
  },
  created() {
    // 获取导师信息
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
    this.getList()
  },
  methods: {
    // 获取学生列表
    getList() {
      let data = {
        subject: this.check.subject,
        subject_id: this.check.subject_id,
        user_name: this.check.user_name,
        order: this.check.order,
        pageSize: this.check.pageSize,
        pageNum: this.check.currentPage,
        parent_id: this.ExpertInfo.id,

      };

      this.$fecth.post("user/getData", data).then((res) => {
        const { code, msg, data } = res;
        if (code == 200) {
          this.UserListData = data.lists;
          this.$emit('setTotal', data.total)
          // this.tables.currentPage = data.pageNum;
        } else {
          this.showTips(0, msg);
        }
      });

    },

    showTips(flag, msg) {
      const title = flag == 1 ? "成功" : "失败";
      const type = flag == 1 ? "success" : "error";
      this.$notify({
        title: title,
        message: msg,
        type: type,
        duration: 2000,
      });
    },
  },
};
</script>

<style scoped lang='less'></style>
