<template>
  <div class="user_list">
    <div class="card" v-for="(item, i) in UserListData" :key="i"
      :class="{ boy: item.is_sex == 1, girl: item.is_sex == 0 }" @click="ToVolunteer(item)">
      <el-row type="flex" align="center" :gutter="10">
        <el-col :span="6">
          <div class="card-item name left">
            {{ item.user_name }}
            <el-image @click.stop="edit(item)" class="img"
              :src="require('./../../../assets/new_icon/edit.png')"></el-image>
          </div>
          <div class="card-item left">{{ item.score }}分</div>
          <div class="card-item left">
            <span class="info_2"> {{ item.subject == 1 ? '物' : '史' }}<span
                v-for=" (item, key) in JSON.parse(item.other_subject) " :key="key">
                <span v-if="item == 2">化</span>
                <span v-if="item == 3">生</span>
                <span v-if="item == 6">政</span>
                <span v-if="item == 5">地</span>
              </span>
            </span>
          </div>
        </el-col>

        <el-col :span="16" :offset="1">
          <div class="card-item school">{{ item.school }}</div>
          <div class="card-item">填报批次:提前、本科</div>
          <div class="card-item">填报状态:未填</div>
        </el-col>
      </el-row>
      <div class="shenhe">
        <span class="shenhe-1">{{ item.name }}</span>

      </div>
    </div>

    <el-drawer :visible.sync="dialogEdit" title="修改个人信息" :with-header="false" size="1220px" destroy-on-close>
      <editUser :userData=form @closeEdit="closeEdit" />
    </el-drawer>
  </div>
</template>

<script>
import editUser from "./editUser";

export default {
  name: "",
  props: ["UserListData", "ExpertList"],
  data() {
    return {
      dialogEdit: false,
      ExpertName: null,
      type: 1,
      user_id: null,
      form: {},
      ExpertInfo: null
    };
  },
  components: {
    editUser,
  },
  created() {
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
  },

  methods: {

    toUserInfo(id, score, subject, name) {
      this.$router.push(
        "/user_info?id=" +
        id +
        "&score=" +
        score +
        "&subject=" +
        subject +
        "&name=" +
        name
      );
    },
    toShenhe(item) {
      if (item.status == 1) {

        if (item.status == 1) {
          this.$confirm('此操作将发送审核, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$fecth.post("user/shenhe", { id: item.id }).then((res) => {
              if (res.code == 200) {
                this.$emit('refresh')
                this.$message({
                  type: 'info',
                  message: `发送审核成功`
                });
              }
            });
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消审核'
            });
          });
        }

      }
    },

    ToVolunteer(item) {
      this.$router.push({
        path: "/tianbao",
        query: {
          id: item.id,
          score: item.score,
          subject: item.subject,
          nav_id: 3
        },
      });
    },

    edit(item) {
      this.form = item;
      this.dialogEdit = true;
    },
    closeEdit() {
      this.dialogEdit = false;
      this.$parent.getList();
    },
  },
};
</script>

<style scoped lang='less'>
.user_list {
  .card {
    margin-bottom: 12px;
    display: inline-block;
    border-radius: 5px;
    margin-right: 6px;
    padding: 10px;
    padding-top: 15px;
    padding-left: 25px;
    width: 420px;
    height: 125px;
    position: relative;


    .name {
      font-size: 22px;
      line-height: 18px;
    }

    .card-item {
      margin-bottom: 13px;
      white-space: nowrap;
      height: 21px;
      /* 保证文本不换行 */
      overflow: hidden;
      /* 隐藏超出容器的文本 */
      text-overflow: ellipsis;
      /* 可选，当需要显示省略号表示文本被截断时使用 */
    }

    .img {
      position: relative;
      top: 4px
    }

    .shenhe {
      position: absolute;
      right: 14px;
      bottom: 14px;

      .shenhe-1 {
        background: #E0EDFF;
        display: inline-block;
        padding: 1px 10px;
        border-radius: 30px;
        color: #2C89FF;
        font-size: 16px;
      }

      .shenhe-2 {
        background: #E0EDFF;
        display: inline-block;
        padding: 1px 10px;
        border-radius: 30px;
        color: #A7A7A7;
        font-size: 16px;
      }

      .shenhe-3 {
        background: #E0EDFF;
        display: inline-block;
        padding: 1px 10px;
        border-radius: 30px;
        color: #2C89FF;
        font-size: 16px;
      }
    }


  }

  .boy {
    border: 2px solid #ADC6F9;
    background: linear-gradient(92deg, #ADC6F9 11%, #FFFFFF 59%);
  }

  .girl {
    background: linear-gradient(92deg, #FF82A4 10%, #FFFFFF 58%);
    border: 2px solid #FF82A4;
  }

  .teacher {
    font-size: 10px;
    border: 1px solid #02e44d;
    display: inline;
    padding: 1px 10px;
    border-radius: 10px;
    color: #02e44d;
  }
}
</style>

<style lang="less">
.user_list {
  min-height: 700px;

  .el-dialog__header {
    text-align: center;
    background: #5b97f9;
    border-radius: 0 0 50px 50px;
    width: 200px;
    height: 40px;
    margin: 0 auto;

    .el-dialog__title {
      color: white;
      line-height: 40px;
      font-size: 16px;
    }
  }

  .el-dialog__header {
    padding: 0;
  }
}
</style>