<template>
  <div class="college">
    <el-row type="flex" align="middle">

      <el-col :span="6" :offset="2">
        <p class="row" style="overflow: hidden">
          <span class="name">{{ item.name }}</span>
        </p>
      </el-col>
      <el-col :span="14" :offset="2">
        <p class="row info">
          <span>类型：
            <div style="display: inline" v-for="(key, i) in schoolType" :key="i">
              <span v-if="key.id == item.school_type_id">{{ key.name }}</span>
            </div>
          </span>
          <span v-if="item.school_belong">隶属：{{ item.school_belong }}</span>
          <span>属地：{{ item.address }}</span>
          <span v-if="item.school_id">在豫招生代号：{{ item.school_id }}</span>
        </p>
        <p class="tip">
          <span @click="getSchoolScoreLine(item.school_id, item.batch)">院校数据</span>
          <span @click="getSPLine(item.school_id)">专业数据</span>
          <span @click="getPlan(item.school_id)">招生计划</span>
          <span @click="getNews(item.id, item.name)">录取规则</span>
          <span @click="toSchoolUrl(item.school_url)">学校主页</span>
          <span @click="toSp(item.school_id)">特色专业</span>
        </p>
      </el-col>
      <el-col :span="4"> </el-col>
    </el-row>

    <el-dialog :title="title" :visible.sync="dialogVisible" :append-to-body="true" width="1000px">
      <component v-bind:is="currentTabComponent" :id="school_id" :batchs="batch"></component>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import CollegeScore from "./CollegeScore";
import SpecialtyScore from "./SpecialtyScore"; 
import Plan from "./Plan";
import toSp from "./toSp";
import schoolNews from "./SchoolNews";
import school_type_array from "@/assets/data/school_type.js";

export default {
  name: "CollegeList",
  props: ["item"],
  components: { CollegeScore, SpecialtyScore, Plan, toSp },
  data() {
    return {
      att: 0,
      dialogVisible: false,
      school_id: "",
      currentTabComponent: "",
      schoolType: [],
      ExpertInfo: [],
      title: "",
      batch: ''
    };
  },
  created() {
    this.schoolType = school_type_array;
  },
  mounted() {
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));
  },
  methods: {
    getSchoolScoreLine(items, batch) {
      this.title = "院校分数";
      this.school_id = items;
      this.batch = batch;
      this.dialogVisible = true;
      this.currentTabComponent = CollegeScore;
    },
    getSPLine(items) {
      this.title = "专业分数";
      this.school_id = items;
      this.dialogVisible = true;
      this.currentTabComponent = SpecialtyScore;
    },
    getPlan(items) {
      this.title = "2024院校招生计划";
      this.school_id = items;
      this.dialogVisible = true;
      this.currentTabComponent = Plan;
    },
    getNews(items, name) {
      this.title = name + "招生录取规则";
      this.school_id = items;
      this.dialogVisible = true;
      this.currentTabComponent = schoolNews;
    },
    toSchoolUrl(url) {
      window.open(url, "_blank");
    },
    toSp(items) {
      this.title = "特色专业";
      this.school_id = items;
      this.dialogVisible = true;
      this.currentTabComponent = toSp;
    },
  },
};
</script>

<style lang="less" scoped>
.college {
  display: block;
  padding: 15px 30px;
  border: 1px solid #dedfe0;
  border-top: 0;

  .school_logo {
    position: relative;
    top: 10px;
  }

  .row {
    height: 29px;
    line-height: 24px;

    span {
      margin-right: 10px;
    }

    &.info {
      width: 120%;
      color: #b8b8b8;
    }

    .name {
      font-size: 16px;
      color: #757575;
    }

    .view {
      color: #ea6f62;
      font-size: 13px;

      .iconfont {
        color: #ea6f62;
        font-size: 22px;
      }
    }

    .tag {
      padding: 5px 14px;
      font-size: 16px;
      border-radius: 40px;
      border: 1px solid #afaeae;
      color: #afaeae;
      position: relative;
      top: -5px;
    }
  }

  .guanzhu {
    color: #03eaff;
    padding: 5px 25px;
    border-radius: 50px;
    margin-top: 60px;
  }
}

.tip {
  span {
    border-radius: 40px;
    border: 1px solid rgb(64, 64, 253);
    color: rgb(64, 64, 253);
    margin-right: 10px;
    font-size: 13px;
    padding: 2px 7px;
    font-weight: 100;
  }
}

.chose {
  background: rgb(64, 64, 253);
  color: white;
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 100;
}

.chose1 {
  border: 1px solid #e3e3e3;
  color: rgb(64, 64, 253);
  padding: 4px 10px;
  font-size: 14px;
  font-weight: 100;
}
</style>
