<template>
  <div>
    <el-popover popper-class="popover-f" style="margin-right: 60px;" placement="bottom" trigger="hover">
      <div class="popover-box">
        <p class="box-title">志愿位置</p>
        <span v-for="h in len" :key="h" class="box-item">
          <span class="box-item-box" style="background: #fbbfbb; border: #fbbfbb;">{{ h }}</span>
        </span>
      </div>
      <el-button size="medium" slot="reference">
        志愿h
      </el-button>
    </el-popover>
  </div>
</template>

<script>

export default {
  data() {
    return {
      planData: [],
      loading: true,
      searchKey: "",
      tiaoji: true,
      spList: [],
      select_code: 1,
      CollegeListData: [],
      currentOffset: 0,
      windowSize: 4,
      paginationFactor: 305,
      len: 45,
      collegeData: {}
    };
  },
  methods: {

  }
};
</script>