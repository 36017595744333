<template>
  <div class="page">
    <div class="item">
      <conditional-search @onSearch='onSearch' v-model="check" :num="page.total" @getReport="getReport" />
    </div>

    <div v-if="reportState">
      <el-row style="text-align: center;" type="flex" align="middle">
        <el-col :span="20">
          <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="remarks"></el-input>
        </el-col>
        <el-col :span="4"><el-button type="success" @click="saveShenhe()">保存审核信息</el-button></el-col>
      </el-row>


    </div>

    <div style="min-height: 100px" v-if="check.type == 0">
      <college-list v-loading="loading" v-for="(item, i) in CollegeData" :rankingData="rankingData" :userInfo="userInfo"
        :oldLine="oldLine" :item="item" :key="i" @refush=updateReport :state="state">
      </college-list>
    </div>
    <div style="min-height: 100px" v-if="check.type == 1">
      <college-list-recommend v-loading="loading" v-for="(item, i) in CollegeData" :rankingData="rankingData"
        :userInfo="userInfo" :oldLine="oldLine" :item="item" :key="i" @refush=updateReport :state="state">
      </college-list-recommend>
    </div>
    <div class="main">
      <template>
        <div style="text-align: center; margin-bottom: 20px">
          <el-pagination @current-change="handleCurrentChange" :page-size="page.pageSize" layout=" prev, pager, next"
            :total="page.total" :current-page.sync="page.currentPage">
          </el-pagination>
        </div>
      </template>
    </div>

  </div>
</template>

<script>
import ConditionalSearch from "./components/ConditionalSearch";
import CollegeList from "./components/CollegeList";
import CollegeListRecommend from "./components/CollegeListRecommend";
export default {
  props: ['userInfo', 'updateStatus', 'rankingData'],

  name: '',
  data() {
    return {
      CollegeData: [],
      //筛选条件
      check: {
        city_id: [],
        nature_id: null,
        school_type_id: [],
        schoolTipId: [],
        type: 0,
        name: null,
        specialty: [],
        min_ranking: null,
        max_ranking: null
      },
      textarea: '',
      kewWord: '',
      page: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
        currentPage: 1,
      },
      new_batch: 1,
      oldLine: [
        { year: 2024, score: 0 },
        { year: 2023, score: 0 },
        { year: 2022, score: 0 },
        { year: 2021, score: 0 }
      ],
      len: 45,
      record: {
        table: [],
        select_code: [],
        specialty: []
      },
      remarks: '',
      state: 1,
      reportState: true
    };
  },
  watch: {
    updateStatus() {
      this.getRecode()
    },
  },
  components: {
    ConditionalSearch,
    CollegeList,
    CollegeListRecommend
  },
  created() {
    if (this.userInfo.subject) {
      let ranking = Number(this.rankingData.ranking)
      switch (true) {
        case ranking < 10000:
          this.check.min_ranking = 1
          this.check.max_ranking = ranking + 10000
          break;
        case 100000 > ranking && ranking > 9999:
          this.check.min_ranking = ranking - 10000
          this.check.max_ranking = ranking + 10000
          break;
        case ranking > 99999:
          this.check.min_ranking = ranking - 15000
          this.check.max_ranking = ranking + 15000
          break;
      }
      this.getRecode()
      this.getList()
      this.getOldCityScore();
    }
  },
  methods: {

    //搜索
    onSearch(e) {
      this.check = e
      console.log(this.check)
      this.page.currentPage = 1
      this.page.pageNum = 1
      this.getList()
    },

    //条件搜索
    getList() {
      // this.reportState = false
      if (this.check.type == 0) {
        this.getOrderCollege()
      } else {
        this.getRecommend()
      }
    },

    //顺序模式
    getOrderCollege() {
      this.$fecth
        .post("volunteer_demand/CollegeFirst", {
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,

          nature_id: this.check.nature_id,
          city_id: this.check.city_id,
          school_type_id: this.check.school_type_id,
          schoolTipId: this.check.schoolTipId,
          name: this.check.name,
          specialty: this.check.specialty,
          min_ranking: this.check.min_ranking,
          max_ranking: this.check.max_ranking,

          score: this.userInfo.score,
          subject_id: this.userInfo.subject_id,
          subject: this.userInfo.subject,

          new_batch: this.new_batch,
        })
        .then((res) => {
          res = res.data;
          this.loading = false;
          this.CollegeData = res.lists;
          this.page.total = res.count;
        });
    },

    //推荐模式
    getRecommend() {
      this.$fecth
        .post("volunteer_demand/recommend", {
          pageNum: this.page.pageNum,
          pageSize: this.page.pageSize,

          nature_id: this.check.nature_id,
          city_id: this.check.city_id,
          school_type_id: this.check.school_type_id,
          schoolTipId: this.check.schoolTipId,
          name: this.check.name,
          specialty: this.check.specialty,
          ranking: this.rankingData.ranking_1 * 5 + this.rankingData.ranking_2 * 3 + this.rankingData.ranking_3 * 2,
          score: this.userInfo.score,
          subject_id: this.userInfo.subject_id,
          subject: this.userInfo.subject,
          new_batch: this.new_batch,
        })
        .then((res) => {
          res = res.data;
          this.loading = false;
          this.CollegeData = res.lists;
          this.page.total = res.count;
        });
    },

    // 根据批次往年录取分数线
    getOldCityScore() {
      this.$fecth
        .post("common/getOldLine", {
          subject: this.userInfo.subject,
          new_batch: this.new_batch,
        })
        .then((res) => {
          this.oldLine = res.data
        });
    },

    //获取志愿表
    getRecode() {
      let data = {
        user_id: this.userInfo.id,
        subject: this.userInfo.subject,
        new_batch: this.new_batch,
      }
      this.$fecth.post("volunteer/getUserReport", data).then((res) => {
        if (res.data.record) {
          this.record = JSON.parse(res.data.record)
          this.remarks = res.data.remarks
        } else {
          for (let i = 0; i < this.len; i++) {
            this.record.table.push({
              name: "暂无",
            });
            this.record.select_code.push('')
            this.record.specialty.push([])
          }
        }
        console.log(this.record, 'record')
        this.$store.commit('setRecord', this.record)
      })
    },

    updateReport(record) {
      this.$fecth
        .post("volunteer/updateReport", {
          user_id: this.userInfo.id,
          subject: this.userInfo.subject,
          new_batch: this.new_batch,
          record: JSON.stringify(record)
        })
      this.state++
    },
    //翻页
    handleCurrentChange(val) {
      this.page.pageNum = val;
      this.getList();
    },
    // 已选志愿
    getReport() {
      this.reportState = true
      this.CollegeData = this.record.table
    },
    saveShenhe() {
      this.$fecth
        .post("volunteer/saveShenhe", {
          user_id: this.userInfo.id,
          subject: this.userInfo.subject,
          new_batch: this.new_batch,
          remarks: this.remarks
        })
      this.state++
    }
  },
};
</script>

<style scoped lang='less'>
.page {
  width: 1400px;
  background: white;
  padding: 20px 60px;
}
</style>
