<template>
  <div class="page">
    <div class="title">
      <el-image style="width: 22px; height: 22px;" :src="require('./../../assets/new_icon/xueyuan.png')"></el-image>
      <router-link to="/">
        <span style="margin-left: 10px;" @click="nav_id = null" :class="{ statusAct: this.nav_id == null }">
          我的学员
          <span style="color: #459EFF;">{{ num0 }}</span> 位
        </span>
      </router-link>

      <router-link to="/user/verify-1" v-if="ExpertInfo.parent_id == 0">
        <span style="margin-left: 40px;" @click="nav_id = 2" :class="{ statusAct: this.nav_id == 2 }">待审核
          <span style="color: #DC3C00;"> {{ num1 }}
          </span>位
        </span>
      </router-link>

      <router-link to="/user/verify-2" v-if="ExpertInfo.parent_id == 0">
        <span style="margin-left: 20px;" @click="nav_id = 3" :class="{ statusAct: this.nav_id == 3 }">已审核
          <span style="color: #459EFF;"> {{ num2 }}
          </span>位
        </span>
      </router-link>

      <span class="add" @click="dialogVisible = true"><el-image class="img"
          :src="require('./../../assets/new_icon/add.png')"></el-image> 添加学员</span>
    </div>

    <div class="subject">
      <!-- 大类 -->
      <el-select v-model="check.subject" class="rounded-select" placeholder="请选择" size="mini" style="width: 120px"
        @change="changeTypeSubject">
        <el-option v-for="item in subjectType" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <!-- 详细分组科类 -->
      <span :class="{ 'subject-name': true, 'subject-name-act': item.id == check.subject_id }"
        v-for="(item, i ) in subjectArray" :key="i" @click="selectSubjectId(item.id)">{{
          item.name }}</span>

      <span class="subject-order" @click="orderList()">按照分数排序
        <el-image class="subject-order-img" :src="require('./../../assets/new_icon/order.png')"></el-image>

      </span>
      <el-input placeholder="请输入内容" v-model="check.user_name" style="width: 220px;float: right; " size="mini"
        class="search">
        <el-button slot="append" class="btn" size="mini" @click="onSearch += 1">搜索</el-button>
      </el-input>
    </div>


    <router-view :check="check" :onSearch="onSearch" @setTotal='setTotal' />

    <!-- 分页 -->
    <div class="pagination">
      <el-pagination :page-size="check.pageSize" :total="total" class="page-box" layout="prev, pager, next"
        @current-change="pageChange" />
    </div>

    <!-- 添加学生弹窗 -->
    <el-drawer :visible.sync="dialogVisible" class="cre" title='添加学员信息' :with-header="false" size="1220px"
      append-to-body destroy-on-close>
      <create_user @closeEdit='close' :userData="null"></create_user>
    </el-drawer>
  </div>
</template>

<script>
import create_user from "./components/editUser";
//time: 2021/1/22
export default {
  name: "User",
  components: {
    create_user,
  },
  data() {
    return {
      url: './../../assets/new_icon/xueyuan.png',
      ExpertInfo: "",
      dialogVisible: false,
      nav_id: null,
      subjectType: [{
        value: 1,
        label: '物理组'
      }, {
        value: 4,
        label: '历史组'
      },
      {
        value: null,
        label: '全部'
      }],
      subjectArray: [],
      subjectArray1: [
        {
          id: 1,
          name: '物化政'
        },
        {
          id: 2,
          name: '物化地'
        },
        {
          id: 3,
          name: '物生政'
        },
        {
          id: 4,
          name: '物化生'
        },
        {
          id: 5,
          name: '物地政'
        },
        {
          id: 6,
          name: '物生地'
        }
      ],
      subjectArray2: [
        {
          id: 7,
          name: '史化政'
        },
        {
          id: 8,
          name: '史政生'
        },
        {
          id: 9,
          name: '史政地'
        },
        {
          id: 10,
          name: '史化地'
        },
        {
          id: 11,
          name: '史化生'
        },
        {
          id: 12,
          name: '史生地'
        }
      ],

      check: {
        subject: null,
        subject_id: null,
        order: "id",
        user_name: null,
        currentPage: 1,
        pageSize: 12,
      },
      onSearch: 1,
      total: 1,
      num0: 0,
      num1: 0,
      num2: 0,
    };
  },
  created() {
    this.subjectArray = this.subjectArray1

    // 获取导师信息
    this.ExpertInfo = JSON.parse(localStorage.getItem("ExpertInfo"));

    //判断登陆状态
    this.checkLoginState()

    this.getNum()

  },
  methods: {
    checkLoginState() {
      let TOKEN = localStorage.getItem("TOKEN")
      this.$fecth.post("login/checkLoginState", { token: TOKEN }).then((res) => {
        console.log(res)
      });
    },

    getNum() {
      this.$fecth.post("user/getUserNum", { parent_id: this.ExpertInfo.id }).then((res) => {
        let { code, msg, data } = res;
        if (code == 200) {
          this.num0 = data.num0
          this.num1 = data.num1
          this.num2 = data.num2
        } else {
          console.log(msg)
        }
      });
    },

    //选科大类
    changeTypeSubject() {
      if (this.check.subject == 1) {
        this.subjectArray = this.subjectArray1
      } else {
        this.subjectArray = this.subjectArray2
      }
      this.check.subject_id = null
      this.onSearch += 1
    },

    //选择科类
    selectSubjectId(id) {
      if (this.check.subject_id == id) {
        this.check.subject_id = null
      } else {
        this.check.subject_id = id
      }
      this.onSearch += 1
    },
    setTotal(e) {
      this.total = e
    },

    //排序
    orderList() {
      this.check.order = this.check.order == 'desc' ? 'asc' : 'desc'
      this.onSearch += 1
    },

    close() {
      this.dialogVisible = false;
      this.onSearch += 1
    },

    // 翻页
    pageChange(val) {
      this.check.currentPage = val;
      this.onSearch += 1
    },
  },
};
</script>
<style lang="less">
.rounded-select {
  .el-input__inner {
    border-radius: 30px;
  }
}

.search {
  .el-input__inner {
    border-radius: 30px 0 0 30px;
  }

  .el-input-group__append,
  .el-input-group__prepend {
    color: white;
    background: #2B7BF3;
    border-radius: 0 30px 30px 0;
    border: #2B7BF3;
  }
}
</style>

<style lang="less" scoped>
.page {
  background: white;
  font-size: 18px;
  padding: 10px 60px;

  .title {
    display: flex;
    align-items: center;

    a {
      color: black;
    }

    /* 垂直居中 */
    // justify-content: center;
    position: relative;

    /* 水平居中，如果需要的话 */
    .add {
      position: absolute;
      right: 0;

      .img {
        width: 22px;
        height: 22px;
        position: relative;
        top: 6px;
        right: 5px;
      }
    }


    .statusAct {
      border-bottom: 2px solid #2C89FF;
    }

    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #DFDFDF;
  }

  .subject {
    margin-top: 20px;
    margin-bottom: 20px;

    .subject-name {
      border: 1px solid #DFDFDF;
      display: inline-block;
      line-height: 26px;
      padding: 0 18px;
      position: relative;
      top: 1px;
      border-radius: 30px;
      margin-left: 20px;
    }

    .subject-order {
      display: inline-block;
      line-height: 26px;
      padding: 0 18px;
      position: relative;
      top: 1px;
      border-radius: 30px;
      margin-left: 20px;

      .subject-order-img {
        position: absolute;
        top: 6px;
        right: -4px;
      }
    }

    .subject-name-act {
      background: #2B7BF3;
      color: white;
    }
  }


}



.pagination {
  background: white;
  padding: 0;
}

.el-dialog__body {
  min-height: 200px;
}

.el-radio-button:last-child .el-radio-button__inner {
  border-radius: 0 4px 0 0;
}

.el-radio-button:first-child .el-radio-button__inner {
  border-radius: 4px 0 0 0;
}
</style>



<style lang="less" scoped>
.choose {
  background: white;
  margin-bottom: 12px;
  padding: 3px;
  display: flex;
  line-height: 25px;

  .fx {
    border-bottom: 1px solid rgb(11, 108, 218);
  }

  div {
    width: 80px;
    margin: 5px 0 5px 75px;
  }
}
</style>